import React, {useState, useEffect} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import Logo from "../assets/images/Logo200px.jpg";

export default function ModalAdd({modal, toggle}) {
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg" className="modal-dialog-centered">
        <ModalBody>
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="mb-5 fw-bold">
                ¡Gracias por confirmar su asistencia a nuestra Convención de
                Distribuidores 2024!
              </h2>
              <h2 className="mb-5 fw-bold">Nos vemos pronto</h2>
              <img src={Logo} width={130} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
