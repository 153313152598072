/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLogin from "../hook/useLogin";
import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";
import logo from "../assets/images/Logo200px.jpg";
import "./login.css";

export default function Index() {
  const { isLoggedIn, login, error, message, rol } = useLogin();
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      Swal.fire({
        title: "Bienvenido",
        text: "Ingreso exitoso",
        icon: "success",
        confirmButtonText: "OK",
      });
      navigate("/registros");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
      setisActive(false);
    }
  }, [error, message]);

  const sendInformacion = (e) => {
    e.preventDefault();
    if (email === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#3b82f6",
        text: "El campo email es obligatorio",
      });
    } else if (password === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        confirmButtonColor: "#3b82f6",
        text: "El campo password es obligatorio",
      });
    } else if (email !== "" && password !== "") {
      setisActive(true);
      login({ email, password });
    }
  };

  return (
    <>
      <LoadingOverlay
        styles={{
          wrapper: {
            height: "100vh",
          },
        }}
        active={isActive}
        spinner
        text="Cargando..."
      >
        <div className="login__background">
          <div className="container">
            <div className="row">
              <div className="col-md-5 mx-auto">
                <form className="login" onSubmit={sendInformacion}>
                  <img
                    className="d-block mx-auto"
                    style={{ width: 180 }}
                    src={logo}
                  />
                  <h3 className="login__title2">¡BIENVENIDO!</h3>

                  <input
                    className="form-control login__input"
                    placeholder="Correo eléctronico"
                    onChange={(e) => setemail(e.target.value)}
                    value={email}
                  />

                  <input
                    className="form-control login__input"
                    type="password"
                    placeholder="Contraseña"
                    onChange={(e) => setpassword(e.target.value)}
                    value={password}
                  />

                  <button
                    type="submit"
                    className="btn login__boton d-block mx-auto my-2 mb-3"
                  >
                    Entrar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}
