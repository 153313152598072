import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/images/Logo200px.jpg';

export default function Header() {
    return <>
        <section className='header'>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="/"><img src={Logo} width={180} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Container>
            </Navbar>
        </section>
    </>
}
