import React, { useState } from "react";
import Logo from "../assets/images/Titulo450px.png";

export default function Header({
    path
}) {

  return (
    <>
      <section className="header">
        <div className="container">
          <div className="row">
            <div className="col-8">
              <ul className="list-inline mt-5 d-none d-sm-none d-md-none d-lg-block">
                <li className="list-inline-item paginado_texto">
                  Registro
                  <div className={path === '/registro' ? "circulo2" : "circulo"}   />
                </li>
                <li
                  className="list-inline-item"
                  style={{color: "transparent", marginLeft: "-36px"}}
                >
                  a <div className="linea" />
                </li>
                <li
                  className="list-inline-item paginado_texto"
                  style={{marginLeft: "-60px"}}
                >
                  Traslado
                  <div className={path === '/traslado' ? "circulo2" : "circulo"}   />
                </li>
                <li
                  className="list-inline-item"
                  style={{color: "transparent", marginLeft: "-36px"}}
                >
                  a <div className="linea" />
                </li>
                <li
                  className="list-inline-item paginado_texto"
                  style={{marginLeft: "-60px"}}
                >
                  Identificación
                  <div className={path === '/identificacion' ? "circulo2" : "circulo"}   />
                </li>
              </ul>

              <ul className="list-unstyled mt-5 d-block d-sm-block d-md-v d-lg-none">
                <li className="paginado_texto">Registro <div className={path === '/registro' ? "circulo2" : "circulo"}   /></li>
                <li className="paginado_texto">Traslado <div className={path === '/traslado' ? "circulo2" : "circulo"}   /></li>
                <li className="paginado_texto">Identificación <div className={path === '/identificacion' ? "circulo2" : "circulo"}   /></li>
              </ul>
            </div>
            <div className="col-4">
              <img src={Logo} className="img-fluid ms-auto d-block mt-5" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h2 className="paginado_titulo">Bienvenido a la página de registro<br/>para la convención de distribuidores 2024</h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
