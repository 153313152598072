import { useCallback, useState } from 'react';
import { RegistroAdd, RegistrosGet } from '../service/registro';

export default function useRegistro() {
    const [Registrados, setRegistrados] = useState([]);
    const [State, setState] = useState({ loading: false, error: false, message: '', success: false });

    const RegistrosGetService = useCallback((token) => {
        RegistrosGet(token).then(response => {
            setRegistrados(response)
        })
    }, []);

    const RegistroAddService = useCallback((
        data
    ) => {
        setState({
            loading: true,
            error: false,
            message: '',
            success: false
        });
        RegistroAdd(
            data
        ).then(response => {
            let errores = response.errors;
            if (errores != undefined) {
                let sap = errores['sap'];
                let razon_social = errores['razon_social'];
                let nombre = errores['nombre'];
                let sexo = errores['sexo'];
                let talla = errores['talla'];
                let trasporte = errores['trasporte'];
                let correo = errores['correo'];
                let hora_llegada = errores['hora_llegada'];
                let codigo_vuelo_llegada = errores['codigo_vuelo_llegada'];
                let aerolinea_llegada = errores['aerolinea_llegada'];
                let hora_salida = errores['hora_salida'];
                let codigo_vuelo_salida = errores['codigo_vuelo_salida'];
                let aerolinea_salida = errores['aerolinea_salida'];
                let alergico = errores['alergico'];
                let llevara_acompanante = errores['llevara_acompanante'];
                let identificacion = errores['identificacion'];

                if (sap != null) { setState({ loading: false, error: true, message: sap[0], success: false }) }
                if (razon_social != null) { setState({ loading: false, error: true, message: razon_social[0], success: false }) }
                if (nombre != null) { setState({ loading: false, error: true, message: nombre[0], success: false }) }
                if (sexo != null) { setState({ loading: false, error: true, message: sexo[0], success: false }) }
                if (talla != null) { setState({ loading: false, error: true, message: talla[0], success: false }) }
                if (trasporte != null) { setState({ loading: false, error: true, message: trasporte[0], success: false }) }
                if (correo != null) { setState({ loading: false, error: true, message: correo[0], success: false }) }
                if (hora_llegada != null) { setState({ loading: false, error: true, message: hora_llegada[0], success: false }) }
                if (codigo_vuelo_llegada != null) { setState({ loading: false, error: true, message: codigo_vuelo_llegada[0], success: false }) }
                if (sexo != null) { setState({ loading: false, error: true, message: sexo[0], success: false }) }
                if (aerolinea_llegada != null) { setState({ loading: false, error: true, message: aerolinea_llegada[0], success: false }) }
                if (hora_salida != null) { setState({ loading: false, error: true, message: hora_salida[0], success: false }) }
                if (codigo_vuelo_salida != null) { setState({ loading: false, error: true, message: codigo_vuelo_salida[0], success: false }) }
                if (aerolinea_salida != null) { setState({ loading: false, error: true, message: aerolinea_salida[0], success: false }) }
                if (alergico != null) { setState({ loading: false, error: true, message: alergico[0], success: false }) }
                if (llevara_acompanante != null) { setState({ loading: false, error: true, message: llevara_acompanante[0], success: false }) }
                if (identificacion != null) { setState({ loading: false, error: true, message: identificacion[0], success: false }) }
            } else {
                if (response.guardado == true) {
                    setState({
                        loading: false,
                        error: false,
                        message: 'Se registro cuenta correctamente',
                        success: true
                    })

                } else {
                    setState({
                        loading: false,
                        error: true,
                        message: 'Error al registrar',
                        success: false,
                    })
                }
            }
        })
    }, [])

    return {
        loading: State.loading,
        error: State.error,
        message: State.message,
        success: State.success,
        Registrados,
        RegistroAddService,
        RegistrosGetService
    }
}