import React, {useState, useEffect} from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  Input,
} from "reactstrap";
import Logo from "../assets/images/Logo200px.jpg";

export default function ModalAdd({modal, toggle, Info}) {    // convertir objeto a array
    
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="lg" className="modal-dialog-centered">
      <ModalHeader toggle={toggle}>Informacion de Distribuidor</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
                <ul className="list-group">
                    {
                        Object.keys(Info).map((key, index) => {
                            // ocultar updated_at y created_at
                            if (key == 'updated_at' || key == 'created_at' || key == 'id') {
                                return;
                            }else{

                                // mostrar imagen de identificacion
                                if (key == 'identificacion') {
                                    return <li className="list-group-item" key={index}>{key}: <img width={200} src={'https://api-convencion.albericlientes.com/images/identificaciones/'+Info[key]} /></li>
                                }else{
                                    return <li className="list-group-item" key={index}>{key}: {Info[key]}</li>

                                }



                            }
                            
                        })  
                    }
                </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
