import React, {useEffect, useState} from "react";
import Paginado from "../components/paginado";
import Swal from "sweetalert2";
import useRegistro from "../hook/useRegistro";
import ModalAdd from "./modal";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileTimePicker} from "@mui/x-date-pickers/MobileTimePicker";

export default function Index() {
  const {success, message, error, RegistroAddService} = useRegistro();
  const [modal, setModal] = useState(false);
  const [path, setPath] = useState("/registro");
  const [isFilePicked, setisFilePicked] = useState(false);
  const [Form, setForm] = useState({
    sap: "",
    razon_social: "",
    nombre_completo: "",
    sexo: "",
    talla: "",
    trasporte: "",
    correo: "",
    hora_llegada: "",
    codigo_vuelo_llegada: "",
    aerolinea_llegada: "",
    hora_salida: "",
    codigo_vuelo_salida: "",
    aerolinea_salida: "",
    alergico: "no",
    alergias: "",
    llevara_acompanante: "",
    identificacion: "",
    comentarios: "",
  });
  const [Validar, setValidar] = useState(false);

  const handleNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      // vallidar que solo sean 8 digitos
      if (e.target.value.length === 8) {
        setValidar(false);
      } else {
        setValidar(true);
      }
      setForm({
        ...Form,
        sap: e.target.value,
      });
    }
  };

  const changeINE = (event) => {
    setForm({
      ...Form,
      identificacion: event.target.files[0],
    });
    setisFilePicked(true);
  };

  const EnviarTraslado = (url) => {
    if (
      Form.sap != "" &&
      Form.sap.length === 8 &&
      Form.razon_social != "" &&
      Form.nombre_completo != "" &&
      Form.sexo != "" &&
      Form.talla != "" &&
      Form.trasporte != "" &&
      Form.correo != ""
    ) {
      setPath(url);
    } else if (
      Form.hora_llegada != "" &&
      Form.codigo_vuelo_llegada != "" &&
      Form.aerolinea_llegada != "" &&
      Form.hora_salida != "" &&
      Form.codigo_vuelo_salida != "" &&
      Form.aerolinea_salida != "" &&
      Form.alergico != "" &&
      Form.llevara_acompanante != ""
    ) {
      setPath(url);
    } else {
      alert("Por favor, complete todos los campos");
    }
  };

  const EnviarIdentificacion = (url) => {
    if (Form.trasporte === "avion") {
      if (
        Form.hora_llegada != "" &&
        Form.codigo_vuelo_llegada != "" &&
        Form.aerolinea_llegada != "" &&
        Form.hora_salida != "" &&
        Form.codigo_vuelo_salida != "" &&
        Form.aerolinea_salida != "" &&
        Form.alergico != "" &&
        Form.llevara_acompanante != ""
      ) {
        if (Form.alergico === "si" && Form.alergias === "") {
          alert("Por favor, especifique el alimento al que es alérgico");
        } else {
          setPath(url);
        }

        if (Form.alergico === "no") {
          setPath(url);
        }
      } else {
        alert("Por favor, complete todos los campos");
      }
    } else if (Form.trasporte === "terrestre") {
      if (Form.alergico != "" && Form.llevara_acompanante != "") {
        if (Form.alergico === "si" && Form.alergias === "") {
          alert("Por favor, especifique el alimento al que es alérgico");
        } else {
          setPath(url);
        }

        if (Form.alergico === "no") {
          setPath(url);
        }
      } else {
        alert("Por favor, complete todos los campos");
      }
    } else {
      alert("Por favor, complete todos los campos");
    }
  };

  const EnviarFormulario = () => {
    let hora_seleccionada = null;
    let hora_seleccionada2 = null;
    if (Form.trasporte === "avion") {
      let horario = Form.hora_llegada.$d;
      hora_seleccionada = horario.getHours() + ":" + horario.getMinutes();

      let horario2 = Form.hora_salida.$d;
      hora_seleccionada2 = horario2.getHours() + ":" + horario2.getMinutes();
    } else {
      hora_seleccionada = null;
      hora_seleccionada2 = null;
    }

    const data = new FormData();
    data.append("sap", Form.sap);
    data.append("razon_social", Form.razon_social);
    data.append("nombre_completo", Form.nombre_completo);
    data.append("sexo", Form.sexo);
    data.append("talla", Form.talla);
    data.append("trasporte", Form.trasporte);
    data.append("correo", Form.correo);
    data.append("hora_llegada", hora_seleccionada);
    data.append("codigo_vuelo_llegada", Form.codigo_vuelo_llegada);
    data.append("aerolinea_llegada", Form.aerolinea_llegada);
    data.append("hora_salida", hora_seleccionada2);
    data.append("codigo_vuelo_salida", Form.codigo_vuelo_salida);
    data.append("aerolinea_salida", Form.aerolinea_salida);
    data.append("alergico", Form.alergico);
    data.append("alergias", Form.alergias);
    data.append("llevara_acompanante", Form.llevara_acompanante);
    data.append("identificacion", Form.identificacion);
    data.append("comentarios", Form.comentarios);

    Swal.fire({
      title: "Registrando",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    RegistroAddService(data);
  };

  useEffect(() => {
    if (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error",
        confirmButtonColor: "#3b82f6",
        text: message,
      });
    }
  }, [message, error]);

  useEffect(() => {
    if (success) {
      Swal.close();
      // abrir modal
      toggle();

      //cerra modal despues de 10 segundos
      setTimeout(() => {
        window.location.href = "/";
      }, 10000);

      setForm({
        sap: "",
        razon_social: "",
        nombre_completo: "",
        sexo: "",
        talla: "",
        trasporte: "",
        correo: "",
        hora_llegada: "",
        codigo_vuelo_llegada: "",
        aerolinea_llegada: "",
        hora_salida: "",
        codigo_vuelo_salida: "",
        aerolinea_salida: "",
        alergico: "",
        alergias: "",
        llevara_acompanante: "",
        identificacion: "",
        comentarios: "",
      });
    }
  }, [message, success]);

  const toggle = () => {
    setModal(!modal);
  };

  console.log(Form);

  return (
    <>
      <ModalAdd modal={modal} toggle={toggle} />
      <section className="fondo2">
        <Paginado path={path} />
      </section>
      {path === "/registro" && (
        <>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <h2 className="registro_titulo">
                  Por favor, complete todos los campos
                </h2>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-3">
                <label className="formulario_texto">
                  No. SAP DE DISTRIBUIDOR
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ingrese los 8 digitos"
                  name="sap"
                  id="sap"
                  value={Form.sap}
                  onChange={(e) => handleNumber(e)}
                />
                {Validar ? (
                  <>
                    <span style={{color: "red"}}>*Ingrese solo 8 digitos</span>
                    <br />
                  </>
                ) : null}
                <span className="formulario_texto_chico">
                  *Puede encontrarlo en sus facturas
                </span>
              </div>
              <div className="col-md-3">
                <label className="formulario_texto">Razón Social</label>
                <input
                  type="text"
                  className="form-control"
                  value={Form.razon_social}
                  onChange={(e) => {
                    setForm({
                      ...Form,
                      razon_social: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="formulario_texto">Nombre Completo</label>
                <input
                  type="text"
                  className="form-control"
                  value={Form.nombre_completo}
                  onChange={(e) => {
                    setForm({
                      ...Form,
                      nombre_completo: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-3">
                <label className="formulario_texto2">Sexo</label>
                <div className="row caja">
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.sexo === "femenino"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        sexo: "femenino",
                      });
                    }}
                  >
                    Femenino
                  </span>
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.sexo === "masculino"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        sexo: "masculino",
                      });
                    }}
                  >
                    Masculino
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <label className="formulario_texto2">Talla camisa</label>
                <div className="row caja">
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.talla === "ch"
                        ? "caja_gris col-md-2 col-sm-2 col-2 mx-auto"
                        : "caja_blanca col-md-2 col-sm-2 col-2 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        talla: "ch",
                      });
                    }}
                  >
                    CH
                  </span>
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.talla === "m"
                        ? "caja_gris col-md-2 col-sm-2 col-2 mx-auto"
                        : "caja_blanca col-md-2 col-sm-2 col-2 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        talla: "m",
                      });
                    }}
                  >
                    M
                  </span>

                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.talla === "g"
                        ? "caja_gris col-md-2 col-sm-2 col-2 mx-auto"
                        : "caja_blanca col-md-2 col-sm-2 col-2 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        talla: "g",
                      });
                    }}
                  >
                    G
                  </span>

                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.talla === "xl"
                        ? "caja_gris col-md-2 col-sm-2 col-2 mx-auto"
                        : "caja_blanca col-md-2 col-sm-2 col-2 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        talla: "xl",
                      });
                    }}
                  >
                    XL
                  </span>

                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.talla === "xxl"
                        ? "caja_gris col-md-2 col-sm-2 col-2 mx-auto"
                        : "caja_blanca col-md-2 col-sm-2 col-2 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        talla: "xxl",
                      });
                    }}
                  >
                    XXL
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <label className="formulario_texto">
                  Medio de transporte para llegar a la ciudad del evento
                </label>
                <div className="row caja">
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.trasporte === "terrestre"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        trasporte: "terrestre",
                      });
                    }}
                  >
                    Terrestre
                  </span>

                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.trasporte === "avion"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        trasporte: "avion",
                      });
                    }}
                  >
                    Avión
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <label className="formulario_texto2">Correo electrónico</label>
                <input
                  type="text"
                  className="form-control"
                  value={Form.correo}
                  onChange={(e) => {
                    setForm({
                      ...Form,
                      correo: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <button
                  className="btn boton_siguiente"
                  onClick={() => {
                    EnviarTraslado("/traslado");
                  }}
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {path === "/traslado" && (
        <>
          <div className="container">
            {Form.trasporte === "avion" ? (
              <>
                <div className="row mt-5">
                  <div className="col-md-2">
                    <label className="formulario_texto4">
                      Hora de LLEGADA al AREOPUERTO de VALLARTA
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack>
                        <MobileTimePicker
                          label=""
                          value={Form.hora_llegada}
                          onChange={(newValue) => {
                            setForm({
                              ...Form,
                              hora_llegada: newValue,
                            });
                          }}
                          ampm={false}
                          style={{width: "100%", backgroundColor: "#fff"}}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <div className="col-md-2">
                    <label className="formulario_texto5">
                      Código de vuelo de llegada
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={Form.codigo_vuelo_llegada}
                      onChange={(e) => {
                        setForm({
                          ...Form,
                          codigo_vuelo_llegada: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="formulario_texto3">Aerolínea</label>
                    <input
                      type="text"
                      className="form-control"
                      value={Form.aerolinea_llegada}
                      onChange={(e) => {
                        setForm({
                          ...Form,
                          aerolinea_llegada: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="formulario_texto2">
                      Hora de SALIDA del vuelo hacia ciudad destino
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack>
                        <MobileTimePicker
                          label=""
                          value={Form.hora_salida}
                          onChange={(newValue) => {
                            setForm({
                              ...Form,
                              hora_salida: newValue,
                            });
                          }}
                          ampm={false}
                          style={{width: "100%", backgroundColor: "#fff"}}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </div>
                  <div className="col-md-2">
                    <label className="formulario_texto6">
                      Código de vuelo de salida
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={Form.codigo_vuelo_salida}
                      onChange={(e) => {
                        setForm({
                          ...Form,
                          codigo_vuelo_salida: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <label className="formulario_texto3">Aerolínea</label>
                    <input
                      type="text"
                      className="form-control"
                      value={Form.aerolinea_salida}
                      onChange={(e) => {
                        setForm({
                          ...Form,
                          aerolinea_salida: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="row mt-5">
              <div className="col-md-3">
                <label className="formulario_texto2">
                  ¿Eres alérgico a algún alimento?
                </label>
                <div className="row caja">
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.alergico === "si"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        alergico: "si",
                      });
                    }}
                  >
                    Si
                  </span>
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.alergico === "no"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        alergico: "no",
                      });
                    }}
                  >
                    No
                  </span>
                </div>
              </div>
              {Form.alergico === "si" ? (
                <>
                  <div className="col-md-3">
                    <label className="formulario_texto">
                      Por favor especifique el alimento al que es alérgico
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={Form.alergias}
                      onChange={(e) => {
                        setForm({
                          ...Form,
                          alergias: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-md-3">
                <label className="formulario_texto7">
                  ¿Llevará acompañante por su cuenta?
                </label>
                <div className="row caja">
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.llevara_acompanante === "si"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        llevara_acompanante: "si",
                      });
                    }}
                  >
                    Si
                  </span>
                  <span
                    style={{cursor: "pointer"}}
                    className={
                      Form.llevara_acompanante === "no"
                        ? "caja_gris col-md-6 col-sm-6 col-6 mx-auto"
                        : "caja_blanca col-md-6 col-sm-6 col-6 mx-auto"
                    }
                    onClick={() => {
                      setForm({
                        ...Form,
                        llevara_acompanante: "no",
                      });
                    }}
                  >
                    No
                  </span>
                </div>
              </div>
              <div className="col-md-3">
                <p className="formulario_texto_chico2">
                  Recuerde que esta persona no podrá ingresar a las actividades
                  del evento y deberá contactar directamente a nuestra agencia
                  para solicitar la persona adicional y realizar el pago.
                </p>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <button
                  className="btn boton_regresar"
                  onClick={() => {
                    setPath("/registro");
                  }}
                >
                  Regresar
                </button>
                <button
                  className="btn boton_siguiente2"
                  onClick={() => {
                    EnviarIdentificacion("/identificacion");
                  }}
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {path === "/identificacion" && (
        <>
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-3">
                <label className="formulario_texto">
                  Adjuntar identificación oficial
                </label>
                <input
                  type="file"
                  className="form-control"
                  placeholder="Ingrese los 8 digitos"
                  onChange={changeINE}
                />
              </div>
              <div className="col-md-5">
                <label className="formulario_texto">Comentarios</label>
                <input
                  type="text"
                  className="form-control"
                  value={Form.comentarios}
                  onChange={(e) => {
                    setForm({
                      ...Form,
                      comentarios: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12 text-center">
                <button
                  className="btn boton_regresar"
                  onClick={() => {
                    setPath("/traslado");
                  }}
                >
                  Regresar
                </button>
                <button
                  className="btn boton_siguiente2"
                  onClick={() => {
                    EnviarFormulario();
                  }}
                >
                  Enviar confirmación
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
