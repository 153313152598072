import React, {useEffect, useState} from "react";
import useRegistro from "../hook/useRegistro";
import imagen from "../assets/images/Fondo2-2.png";
import {Box} from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Chip,
  IconButton,
  TableFooter,
  TablePagination,
  Button,
  FormControlLabel,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {useTheme} from "@emotion/react";
import ModalRegistros from "./modal_registros";
import useLogin from "../hook/useLogin";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

const columns = [
  {id: "id", label: "#", minWidth: 170},
  {id: "id", label: "Sap", minWidth: 170},
  {id: "fecha", label: "Razon Social", minWidth: 100},
  {id: "Serial", label: "Nombre completo", minWidth: 100},
  {id: "Modelo", label: "Correo", minWidth: 100},
  {id: "Puntos", label: "Comentario", minWidth: 100},
  {id: "Factura", label: "Ver", minWidth: 100},
];

export default function Index() {
  const {success, message, error, RegistrosGetService, Registrados} =
    useRegistro();
  const {token, logout} = useLogin();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modal, setModal] = useState(false);
  const [Info, setInfo] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function TablePaginationActions(props) {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{flexShrink: 0, ml: 2.5}}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    RegistrosGetService(token);
  }, [RegistrosGetService]);

  const ExportExcel = (array) => {
    const newArray = array.map((res) => {
      delete res.updated_at;
      delete res.identificacion;
      return res;
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(newArray);
    const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
    const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, "Distribuidores" + fileExtension);
  };

  const closeSession = () => {
    logout();
  };

  return (
    <>
      <ModalRegistros modal={modal} toggle={toggle} Info={Info} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <img className="img-fluid" src={imagen} />
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-6">
            <h2 className="text-white mb-5">Listado de Distribuidores</h2>
          </div>
          <div className="col-md-6 text-end">
            <button
              className="btn btn-success btn-sm"
              onClick={() => ExportExcel(Registrados)}
            >
              Exporta en Excel
            </button>
            <button className="btn btn-danger btn-sm" onClick={()=>{
              closeSession();
            }}>Cerrar Sesión</button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={index}
                        className="text-center"
                        style={{background: "#ed7100"}}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Registrados.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {border: 0},
                        }}
                      >
                        <TableCell key={index} className="text-center">
                          {(page + 1) * rowsPerPage - rowsPerPage + (index + 1)}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.sap}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.razon_social}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.nombre_completo}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.correo}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          {row.comentarios}
                        </TableCell>
                        <TableCell key={index} className="text-center">
                          <button
                            className="btn boton_siguiente"
                            style={{fontSize: 12, width: 180}}
                            onClick={() => {
                              setInfo(row);
                              setModal(true);
                            }}
                          >
                            Ver inforación
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        {label: "Todos", value: -1},
                      ]}
                      colSpan={10}
                      count={Registrados.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "filas por página",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
