const ENDPOINT = 'https://api-convencion.albericlientes.com/api/auth/';
const ENDPOINTP = 'https://api-convencion.albericlientes.com/api/';


export async function Login(data) {
    return fetch(`${ENDPOINT}login`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}

export async function Logout(token) {
    return fetch(`${ENDPOINTP}logout`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}