const ENDPOINT = 'https://api-convencion.albericlientes.com/api/auth/';
const ENDPOINTP = 'https://api-convencion.albericlientes.com/api/';

export async function RegistroAdd(data, token) {
    return fetch(`${ENDPOINT}registerAdd`, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        method: 'post',
        body: data
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function RegistrosGet(token) {
    return fetch(`${ENDPOINTP}registrosGet`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `${token}`
        },
        method: 'get',
    }).then(res => res.json()).then(response => {
        return response;
    });
}