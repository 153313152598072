import React from "react";
import Header from "../components/header";
import Titulo from "../assets/images/Titulo1200px.png";
import Equipos from "../assets/images/Equiposqueinspiran.png";
import {useNavigate} from "react-router-dom";

export default function Index() {
  let navigate = useNavigate();

  //funcion enviar a registro
  const enviarRegistro = () => {
    navigate("/registro");
  };
  return (
    <>
      <section className="fondo">
        <Header />
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center vh-100">
            <div className="col-12 text-center">
              <img src={Titulo} className="img-fluid mx-auto d-block" />
              <br />
              <button className="btn boton_registro" onClick={()=>{enviarRegistro()}}>Inciar registro</button>
              <br />
              <img src={Equipos} className="img-fluid ms-auto d-block d-none d-sm-none d-md-block d-lg-block" />
              <img src={Equipos} className="img-fluid mx-auto d-block d-block d-sm-block d-md-none d-lg-none" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
