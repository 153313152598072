import * as React from "react";
import {Routes, Route} from "react-router-dom";
import Index from "./pages/index";
import Login from "./pages/login";
import Registro from "./pages/registro";
import Registros from "./pages/registros";
import PrivateRoute from "./route/PrivateRoute";
import {UserContextProvider} from "./context/userContext";
import "./App.css";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="registro" element={<Registro />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="registros" element={<Registros />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;
